<template>
  <div :class="fullWidth ? 'w-full' : ''">
    <Button
      :class="fullWidth ? 'w-full mb-2' : ''"
      v-if="isSearch != true"
      label="Search"
      class="p-button-text p-button-secondary text-left"
      @click="showSearch()"
      icon="las la-search"
    />
    <span
      :class="(fullWidth ? 'w-full mb-2 ' : '') + 'p-input-icon-right'"
      v-else
    >
      <i
        class="pi pi-times cursor-pointer"
        @click="hideSearch()"
      />
      <InputText
        :class="fullWidth ? 'w-full' : ''"
        ref="search"
        clearable
        type="text"
        placeholder="Search"
        icon="pi pi-search"
        v-model="search"
      />
    </span>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  data() {
    return {
      isSearch: false,
    };
  },
  props: {
    value: {
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showSearch() {
      this.isSearch = true;
      this.$nextTick(() => {
        this.$refs.search.$el.focus();
      });
    },
    hideSearch() {
      this.search = null;
      this.$nextTick(() => {
        this.isSearch = false;
      });
    },
  },
  created() {
    this.debouncedSearch = debounce((val) => {
      this.$emit("input", val);
    }, this.debounce);
  },

  beforeDestroy() {
    this.debouncedSearch.cancel();
  },

  computed: {
    search: {
      get() {
        return this.value;
      },
      set(val) {
        this.debouncedSearch(val);
      },
    },
  },
};
</script>

<style>
</style>