<template>
  <div>
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />

    <div v-show="!isLoading">
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col-12">
          <h2>
            <template v-if="obj == null || obj.ce_from_template === 1">
              Create New Cost Estimate
            </template>
            <template v-else>Edit Cost Estimate</template>
          </h2>
        </div>
        <CostEstimateForm
          :ceProp="obj"
          :isNew="obj == null || obj.ce_from_template === 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  fetchEstimateCategories,
  fetchEstimateTerms,
  fetchEstimateClients,
} from "../../../services/data_service.js";
import CostEstimateForm from "../../Finance/components/CostEstimateForm.vue";
export default {
  name: "CostEstimateSlider",
  components: {
    CostEstimateForm,
  },
  created() {
    this.$store.dispatch("setSliderLoader", true);
  },
  methods: {
    async loadData() {
      if (this.obj) {
        await Promise.all([
          fetchEstimateCategories(),
          fetchEstimateTerms(),
          fetchEstimateClients(),
        ]);
      } else {
        await Promise.all([
          fetchEstimateCategories(),
          fetchEstimateTerms(),
          fetchEstimateClients(),
        ]);
      }
    },
  },
  async mounted() {
    await this.loadData();
    this.$store.dispatch("setSliderLoader", false);
  },
  computed: {
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
  },
};
</script>