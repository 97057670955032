<template>
  <form
    @submit.prevent="submitHandler"
    @keyup.enter="handleKeyPress"
    autocomplete="off"
  >
    <div
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      class="relative"
    >
      <div
        class="formgrid frid flex align-items-center justify-content-between"
        v-if="editAmmendment === false"
      >
        <div
          v-html="amendment.brief"
          style="overflow:hidden"
        ></div>
        <div
          v-if="hover"
          style="position:absolute;right:0;top:0"
        >
          <i
            @click="editAmmendment = true"
            class="las la-pen i-20 cursor-pointer grey pr-1"
          ></i>
          <i
            @click.stop="handleDelete(amendment)"
            class="las la-trash cursor-pointer i-20 grey"
          ></i>
        </div>
      </div>
      <template v-else>
        <!-- <InputText class="mb-2" v-model="amendment.title" /> -->
        <div class="field col-12 sm:col-12 mt-2">
          <div class="p-inputgroup">
            <InputText v-model="amendment.title" />
          </div>
        </div>
        <Editor
          ref="editor"
          id="Description"
          :autoResize="true"
          v-model="amendment.brief"
          editorStyle="height:150px"
        >
          <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button><button class="ql-strike"></button>
              <button
                class="ql-list"
                value="ordered"
              ></button>
              <button
                class="ql-list"
                value="bullet"
              ></button>
              <button class="ql-align"></button>
              <button class="ql-link"></button>
            </span> </template>
        </Editor>
      </template>
    </div>
    <div
      class="field mt-3 font-light"
      v-if="amendment.files && amendment.files.length > 0"
    >
      Documents
    </div>
    <div
      v-for="(f, index) in amendment.files"
      :key="f.uid"
      :class="'field  ' + (index === 0 ? 'mt-3' : '')"
    >
      <Attachment :file="f"></Attachment>
    </div>
    <Button
      v-if="showDrop === false"
      icon="las la-plus"
      label="Add a Document"
      @click="showDrop = true"
      class="p-button-text p-button-secondary p-button-sm mt-3"
    />
    <div
      class="field col-12 cursor-pointer mt-3"
      v-if="showDrop === true"
    >
      <file-pond
        ref="pond"
        name="profile"
        class="font-light"
        label-idle="Drop documents or select files"
        v-bind:allow-multiple="true"
        v-bind:files="myFiles"
        v-on:init="handleFilePondInit(amendment.uid)"
        v-on:processfile="handleProcessFile"
      />
    </div>
    <div
      v-if="showDrop === true || editAmmendment === true"
      class="flex justify-content-between mt-3"
    >
      <Button
        type="submit"
        :loading="isLoading"
        label="SAVE"
      ></Button>
      <Button
        class="p-button-text p-button-secondary"
        @click="
          showDrop = false;
          editAmmendment = false;
        "
      >
        CANCEL
      </Button>
    </div>
  </form>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import Attachment from "@/application/Tasks/components/Attachment";
import { fetchAmendments } from "../../../services/data_service.js";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
var FilePond = vueFilePond(FilePondPluginFileValidateType);
export default {
  components: {
    FilePond,
    Attachment,
  },
  props: {
    amendment: {
      type: Object,
    },
    task: {
      type: Object,
    },
  },
  data() {
    return {
      hover: false,
      editAmmendment: false,
      showDrop: false,
      task_files: [],
      myFiles: [],
      isLoading: false,
    };
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async submitHandler() {
      try {
        this.isLoading = true;
        var form = new FormData();

        if (this.task_files && this.task_files.length > 0) {
          form.append("task_files", JSON.stringify(this.task_files));
        }

        form.append("title", this.amendment.title);
        form.append("brief", this.amendment.brief);
        form.append("estimated_hours", this.amendment.estimated_hours);

        form.append("task", this.task.uid);

        await this.$axios.post(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/amendment/" +
            this.amendment.uid,
          form,
          {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          }
        );
        await fetchAmendments(this.task.uid);
        this.editAmmendment = false;
        this.isLoading = false;
        this.$toast.add({
          severity: "success",
          summary: "Amendment saved",
          life: 3000,
        });
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.status === 401) {
          console.error("NOOOOOOOOO!");
        } else {
          // Handle error however you want
        }
        console.error(error);
      }
    },
    handleProcessFile: function (error, file) {
      const response = JSON.parse(file.serverId);
      this.task_files.push(response.data);
    },
    handleFilePondInit() {
      this.$refs["pond"].getFiles();
    },
    handleDelete(amendment) {
      this.deletePrompt(
        amendment.title,
        "/v1/tasks/amendment/" + amendment.uid
      ).then((res) => {
        fetchAmendments(this.task.uid);
      });
    },
  },
  async mounted() {
    setOptions({
      server: {
        url: process.env.VUE_APP_ROOT_API + "/",
        timeout: 7000,
        process: {
          url: "./v1/tasks/upload",
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.$store.getters.token,
          },
        },
      },
    });
  },
};
</script>

<style>
</style>