<template>
  <div>
    <div class="formgrid grid flex align-items-center mt-3">
      <div class="field col-12">
        <div class="align-items-center flex mb-3 text-16">
          {{ task?.task_type_name }}
        </div>
        <div
          v-html="markdownDescription"
          class="mb-3"
        ></div>
      </div>
      <div
        class="field col-4 align-items-center flex"
        v-if="task?.date_start"
      >
        <i class="las la-calendar i-18 mr-1 text-primary" />
        <div class="font-light mr-3">Start</div>
        <div>{{ task?.date_start?.nice }}</div>
      </div>
      <div
        class="field col-4 align-items-center flex"
        v-if="task?.date_due"
      >
        <i class="las la-calendar i-18 mr-1 text-primary" />
        <div class="font-light mr-3">Due</div>
        <div>{{ task?.date_due?.nice }}</div>
      </div>
      <div class="field col-4 align-items-center flex">
        <i class="far fa-fw fa-stopwatch i-16 mr-1 text-primary" />
        <div class="font-light mr-3">Additional Time</div>
        <div>{{ task?.amendment_hours ?? "0:00" }}</div>
      </div>
      <div class="field col-4 align-items-center flex">
        <i class="far fa-fw fa-tags i-16 mr-1 text-primary" />
        <div class="font-light mr-3">Rate/Hour</div>
        <span class="text-primary">{{ formatCurrency(task?.rate, job?.client?.currency?.description) }}
        </span>
      </div>
      <div class="field col-4 align-items-center flex">
        <i class="far fa-fw fa-wallet i-16 mr-1 text-primary" />
        <div class="font-light mr-3">Billable</div>
        <span class="text-primary">{{
          task?.billable == 1 ? "Yes" : "No"
        }}</span>
      </div>
      <div class="field col-4 align-items-center flex">
        <i class="far fa-fw fa-stopwatch i-16 mr-1 text-primary" />
        <div class="font-light mr-3">Estimated Time</div>
        <div>{{ task?.original_hours }}</div>
      </div>

      <div
        v-if="taskUsers?.length > 0"
        class="field flex flex-wrap col-12 align-items-center mt-3 justify-content-between"
      >
        <div class="col-6 pl-0">Total Time Tracked</div>
        <div class="col-6 pr-0 text-right">
          {{ !task?.hours ? "00:00" : task?.hours }} / {{ task?.estimated_hours }}
        </div>

        <div class="flex col-12 pl-0 pr-0 align-items-center mt-2">
          <div
            class="surface-300 border-round overflow-hidden w-full"
            style="height: 6px"
          >
            <div
              class="h-full"
              :style="getProgressStyle(task)"
            />
          </div>
        </div>
      </div>
      <Divider class="m-2 mb-4 mt-3"></Divider>

      <template v-if="isJobCosting">
        <div class="flex align-items-center col-12">
          <div class="p-inputgroup align-items-center">
            <AInputSwitch
              :value="task?.is_approved === 1 ? 1 : 0"
              @input="updateItemStatus()"
              class="green"
            />
            <label class="pl-3">Activate Task</label>
          </div>
        </div>

        <Divider class="m-2 mb-5 mt-3"></Divider>
      </template>
    </div>
    <div
      class="field col-12 align-items-center flex font-light"
      v-if="taskUsers?.length > 0"
    >
      Assigned to
    </div>
    <TaskAssignee
      v-for="u in taskUsers"
      :key="u.uid"
      :user="u"
      :task="task"
    ></TaskAssignee>

    <Button
      v-if="task?.is_approved == 1"
      label="Assign a User"
      class="p-button-text p-button-secondary"
      @click="
        $store.dispatch('setSelectedObject', task);
        $store.dispatch('setPopupDialog', 'UserSelect');
      "
      icon="las la-plus"
    />
    <Divider
      class="m-2 mb-5 mt-3"
      v-if="taskUsers?.length > 0 || !isJobCosting"
    ></Divider>
    <div
      v-if="task?.review_user"
      class="field col-12 align-items-center flex font-light"
    >
      Reviewed by
    </div>
    <div class="formgrid p-fluid grid flex align-items-center">
      <div
        v-if="task?.review_user"
        :class="
          'col-6 field ' + (task?.review_user.active !== 1 ? 'opacity-50' : '')
        "
      >
        <div class="flex align-items-center">
          <ProgressAvatar
            :user="task?.review_user"
            :image="task?.review_user.profile_picture"
            :progress="task?.review_user.progress_bar"
            :strokeColor="
              task?.review_user.progress_bar >= 100 ? '#fe4d97' : '#6dd230'
            "
            innerStrokeColor="#ffffff"
            :radius="25"
            class="mr-2"
          ></ProgressAvatar>{{ task?.review_user.name }} {{ task?.review_user.surname }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from "../../../services/data_service.js";

import TaskAssignee from "./TaskAssignee.vue";
export default {
  props: {
    task: {
      type: Object,
    },
  },
  components: {
    TaskAssignee,
  },

  computed: {
    markdownDescription() {
      return this.task?.description?.replace(/\n/g, "<br>");
    },
    taskUsers() {
      return state.task?.users;
    },
    isJobCosting() {
      return this.$route.name === "job--costing";
    },
    job() {
      return state.job;
    },
  },
  async mounted() {
    state.taskUsers = null;
  },

  methods: {
    updateItemStatus() {
      this.task.is_approved = this.task.is_approved === 1 ? 0 : 1;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/jobs/" +
            this.job.uid +
            "/approve/" +
            this.task.uid,
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.$toast.add({
            severity: "success",
            summary: "Item Status Updated",
            //detail: title + " has been added",
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },

    getProgressStyle(task) {
      if (task.progress_bar >= 100) {
        return "background: #fe4d97;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;";
      } else {
        return (
          "border-bottom-left-radius: 4px;background: #6dd230;width:" +
          (task.progress_bar ? parseInt(task.progress_bar) : 0) +
          "%"
        );
      }
    },
    toggleUser(user_uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.task.uid +
            "/assign/" +
            user_uid,
          {}
        )

        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
</style>