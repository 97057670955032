<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />
    <div
      v-show="!isLoading"
      id="ellipsis"
    >
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col-10 justify-content-start">
          <h2>Cost Detail</h2>
          <div
            v-if="job"
            class="font-light"
          >
            <div class="text-link-hover">
              {{ job.job_number }} - {{ job.title }}
            </div>
          </div>
        </div>
        <div class="field col-2">
          <div
            v-if="cost_uid"
            class="p-inputgroup flex justify-content-end align-items-center"
          >
            <div class="relative">
              <Button
                @click="markComplete()"
                style="height: 30px; width: 30px"
                icon="las la-check-circle i-20"
                class="p-button-sm p-button-rounded p-12 mr-2 cursor-pointer grey-button"
                :class="
                  'p-button-sm p-button-rounded p-12 mr-2 cursor-pointer ' +
                  getCostCompleteClass(cost?.is_complete)
                "
                v-if="!isSwitching"
              />
              <ProgressSpinner
                class="p-12 h-2rem w-2rem p-0 mr-2"
                style="width: 18px; height: 18px"
                v-else
              />
            </div>
            <i
              v-if="!costFormMode"
              class="las la-pen text-16 mr-3 cursor-pointer"
              @click="costFormMode = true"
            />
            <i
              class="las la-ellipsis-h text-20 cursor-pointer"
              @click="toggle"
            />
            <Menu
              :model="items"
              ref="subMenu"
              :popup="true"
              appendTo="body"
            />
          </div>
        </div>
        <div class="col-12">
          <transition
            name="slide-fade"
            style="overflow-x: hidden"
          >
            <template>
              <CostForm
                @cancel="costFormMode = !costFormMode"
                :costProp="cost"
                v-if="costFormMode"
              ></CostForm>
              <CostOverview
                :cost="cost"
                v-else
              > </CostOverview>
            </template>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { state, fetchCost } from "../../../services/data_service.js";
import CostForm from "./JobCostForm";
import CostOverview from "./JobCostOverview";
export default {
  components: {
    CostOverview,
    CostForm,
  },
  name: "CostSlider",
  data() {
    return {
      isSwitching: false,
      index: 0,
      costFormMode: false,
      contextMenuItems: [
        {
          label: "Edit Cost",
          command: () => {
            this.costFormMode = true;
          },
        },
        {
          label: "Delete Cost",
          command: () => {
            this.deletePrompt(
              this.costObj.type_name,
              "/v1/costs/" + this.cost_uid
            );
            this.clearSliderContent();
          },
        },
      ],
    };
  },
  async mounted() {
    this.$store.dispatch("setSliderLoader", true);

    if (!this.cost_uid) {
      this.costFormMode = true;
      state.cost = null;
      this.$store.dispatch("setSliderLoader", false);
    } else {
      this.costFormMode = this.selectedObject.mode === "edit";

      await this.loadData();
      this.$store.dispatch("setSliderLoader", false);
    }
  },

  methods: {
    getCostCompleteClass(c) {
      return c == "1" ? "cost-icon-complete grey-button" : "grey-button";
    },
    markComplete() {
      this.isSwitching = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API + `/v1/costs/${this.cost_uid}/complete`,
          {}
        )
        .then((response) => {
          if (response.data.data) {
            this.cost.is_complete = this.cost.is_complete == 1 ? 0 : 1;
            this.$toast.add({
              severity: "success",
              summary:
                "Cost marked as " +
                (this.cost.is_complete == 1 ? "completed" : "incomplete"),

              life: 2000,
            });
          }

          this.$bus.emit("refreshData");
          this.isSwitching = false;
        });
    },

    toggle(event) {
      this.$refs.subMenu.toggle(event);
    },
    async loadData() {
      await Promise.all([fetchCost(this.cost_uid)]);
    },
    getContextMenu(array) {
      var arr = [];
      array.forEach((element) => {
        arr.push(this.contextMenuItems[element]);
      });
      return arr;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
    items() {
      return this.getContextMenu([0, 1]);
    },
    setSuffix() {
      let suffix = null;
      if (this.cost?.markup_method === "Percentage") {
        suffix = "%";
      } else {
        suffix = null;
      }
      return suffix;
    },
    cost() {
      return state.cost;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    job() {
      return this.selectedObject.job;
    },
    group() {
      return this.selectedObject.group;
    },
    cost_uid() {
      return this.selectedObject.cost_uid;
    },
  },
  watch: {
    isLoading(n) {
      if (n == false && this.selectedObject.mode !== "edit") {
        this.costFormMode = !this.cost_uid;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cost-icon-complete {
  background-color: #6dd230 !important;
  border-color: #6dd230 !important;
  color: white !important;
}
</style>