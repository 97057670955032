<template>
  <div>
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />

    <div v-show="!isLoading">
      <div class="formgrid grid flex align-items-center">
        <div class="field col-12 align-items-center pl-0">
          <div class="flex align-items-center">
            <h2 class="mb-0">
              <template v-if="obj == null || obj.job_from_template === 1">
                Create New Job
              </template>
              <template v-else>
                <span v-if="!obj.copy">Edit</span><span v-else>Copy</span> Job
              </template>
            </h2>

            <span
              class="text-12 pl-4"
              v-if="obj"
            >
              <span
                v-if="job?.accepted === 1"
                :style="getTagStyle('Accepted')"
                class="p-2"
              >Accepted</span>
              <span
                v-else-if="job?.status"
                :style="getTagStyle('Accepted') + ' borderRadius: 6px'"
                class="p-2"
              >{{ toTitleCase(job?.status) }}</span>
            </span>
          </div>
        </div>

        <TabView
          v-if="obj && obj.job_from_template !== 1"
          class="col-12 pl-0 pb-3"
          v-bind:activeIndex.sync="activeIndex"
        >
          <TabPanel>
            <template #header>
              <i class="la la-suitcase"></i>
              <span
                class="pl-2"
                style="font-weight:500"
              >General</span>
            </template>

          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="la la-redo-alt"></i>
              <span
                class="pl-2"
                style="font-weight:500"
              >Recurring</span>
            </template>

          </TabPanel>
        </TabView>
        <template v-if="isMounted">
          <JobForm
            v-if="activeIndex === 0 || obj == null"
            :jobProp="obj"
            :isNew="obj == null || obj.job_from_template === 1"
          />

          <JobRecurring
            v-if="activeIndex === 1 || obj == null"
            :jobProp="obj"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {
  state,
  fetchUsersData,
  fetchJob,
  fetchJobsStatus,
  fetchClients,
  fetchCategories,
} from "../../../services/data_service.js";
import JobForm from "../../Job/components/JobForm.vue";
import JobRecurring from "../../Job/components/JobRecurring.vue";
export default {
  name: "UserSliderDetails",
  components: {
    JobForm,
    JobRecurring,
  },
  data() {
    return {
      activeIndex: 0,
      status: null,
      job: null,
      isMounted: false,
    };
  },
  created() {
    this.$store.dispatch("setSliderLoader", true);
  },
  methods: {
    async loadData() {
      const fetchPromises = [
        fetchUsersData(),
        fetchCategories(),
        fetchJobsStatus(),
        fetchClients(),
      ];

      if (this.obj) {
        fetchPromises.push(fetchJob(this.obj.uid));
      }

      await Promise.all(fetchPromises);
    },
    handleContacts(id) {
      const client = this.clients.find((client) => client.uid === id);
      if (client) {
        this.contacts = client.contacts;
        this.currency = client.currency.name.substring(0, 4);
      }
    },
    getTagStyle() {
      switch (this.status) {
        case "Overdue":
          return "background: #FFF1F7 !important; color: #FE4D97 !important;";
        case "Warning": // Adjusted condition assuming this was the intended logic
          return "background: #f4fbef !important; color: #FFAB2B !important;";
        default:
          return "background: #f4fbef !important; color: #6dd230 !important;";
      }
    },
    initializeJob() {
      const { date_due, budget, managed_by, client, start_date, category_uid } =
        this.obj;

      this.job = { ...this.obj };
      this.job.due_date = date_due.date
        ? new Date(date_due.date).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0];
      this.job.budget_amount = budget?.amount;
      this.managed_by = managed_by?.uid;
      this.client = client?.uid;
      this.job.start_date =
        start_date ?? new Date().toISOString().split("T")[0];
      this.job.category = category_uid;
      this.contact = client?.contact;

      this.handleContacts(client?.uid);
    },
    setDefaultStatus() {
      const plannedStatus = this.jobsStatus.find(
        (job) => job.name === "Planned"
      );
      this.status = plannedStatus?.uid;
    },
  },
  async mounted() {
    await this.loadData();
    console.log(this.obj);
    if (this.obj) {
      this.initializeJob();
    } else {
      this.setDefaultStatus();
    }
    this.isMounted = true;
    this.$store.dispatch("setSliderLoader", false);
  },
  computed: {
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    clients() {
      return state.clients;
    },
    jobsStatus() {
      return state.jobsStatus;
    },
  },
};
</script>