<template>
  <div>
    <RocketLoad
      v-if="isLoading"
      :full="true"
    />

    <div v-show="!isLoading">
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col-12 align-items-center pl-0">
          <div class="flex align-items-center">
            <h2 class="mb-0">
              <template v-if="obj == null"> New Costing </template>
              <template v-else> Edit Costing </template>
            </h2>

            <span
              class="text-12 pl-4"
              v-if="jobsStatus && obj"
            >
              <span
                :style="
                  getTagStyle(
                    jobsStatus.find((a) => a.uid === obj.status)?.name
                  ) + 'borderRadius: 6px'
                "
                class="p-2"
              >{{ jobsStatus.find((a) => a.uid === obj.status)?.name }}</span>
            </span>
            <span
              class="text-12 pl-4"
              v-else
            >
              <span
                :style="
                  getTagStyle(jobsStatus.find((a) => a.uid === status)?.name) +
                  'borderRadius: 6px'
                "
                class="p-2"
              >{{ jobsStatus.find((a) => a.uid === status)?.name }}</span>
            </span>
          </div>
        </div>

        <JobCostingForm
          :jobCostingProp="obj"
          :isNew="obj == null"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  state,
  fetchUsersData,
  fetchJobsStatus,
  fetchClients,
  fetchCategories,
} from "../../../services/data_service.js";
import JobCostingForm from "@/application/JobCosting/components/JobCostingForm.vue";
export default {
  name: "UserSliderDetails",
  components: {
    JobCostingForm,
  },
  data() {
    return {
      index: 0,
      status: null,
    };
  },
  created() {
    this.$store.dispatch("setSliderLoader", true);
  },
  methods: {
    async loadData() {
      if (this.obj) {
        await Promise.all([
          fetchUsersData(),
          fetchCategories(),
          fetchJobsStatus(),
          fetchClients(),
        ]);
      } else {
        await Promise.all([
          fetchUsersData(),
          fetchCategories(),
          fetchJobsStatus(),
          fetchClients(),
        ]);
      }
    },
    getTagStyle(status) {
      if (status === "Overdue") {
        return "background: #FFF1F7 !important;color: #FE4D97 !important;";
      } else if (status === "Overdue") {
        return "background: #f4fbef !important;color: #FFAB2B !important;";
      }
      return "background: #f4fbef !important;color: #6dd230 !important;";
    },
  },
  async mounted() {
    await this.loadData();
    this.status = this.jobsStatus.find((job) => job.name === "Planned").uid;
    this.$store.dispatch("setSliderLoader", false);
  },
  computed: {
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    clients() {
      return state.clients;
    },
    jobsStatus() {
      return state.jobsStatus;
    },
  },
};
</script>