<template>
  <div>
    <div class="formgrid grid flex align-items-center mt-3">
      <div class="field col-12">
        <div class="align-items-center flex mb-3 text-16">
          {{ task?.task_type_name }}
        </div>
        <div
          v-html="markdownDescription"
          class="mb-3"
        ></div>
      </div>

      <div class="field col-6 align-items-center flex">
        <i class="far fa-fw fa-stopwatch i-16 mr-1 font-light" />
        <div class="font-light mr-3">Estimated Time</div>
        <div>{{ task?.estimated_hours }}</div>
      </div>
      <div class="field col-6 align-items-center flex">
        <i class="far fa-fw fa-stopwatch i-16 mr-1 font-light" />
        <div class="font-light mr-3">Billable</div>
        CE 24135
      </div>
      <div class="field col-6 align-items-center flex">
        <i class="las la-calendar i-18 mr-1 font-light" />
        <div class="font-light mr-3">Start</div>
        <div>{{ task?.date_start?.nice }}</div>
      </div>
      <div class="field col-6 align-items-center flex">
        <i class="las la-calendar i-18 mr-1 font-light" />
        <div class="font-light mr-3">Due</div>
        <div>{{ task?.date_due?.nice }}</div>
      </div>
      <div class="field flex flex-wrap col-12 align-items-center mt-3 justify-content-between">
        <div class="col-6 pl-0">Total Time Tracked</div>
        <div class="col-6 pr-0 text-right">
          {{ !task?.hours ? "00:00" : task?.hours }} / {{ task?.estimated_hours }}
        </div>

        <div class="flex col-12 pl-0 pr-0 align-items-center mt-2">
          <div
            class="surface-300 border-round overflow-hidden w-full"
            style="height: 6px"
          >
            <div
              class="h-full"
              :style="getProgressStyle(task)"
            />
          </div>
        </div>
      </div>
      <Divider class="m-2 mb-5 mt-3"></Divider>
    </div>
    <div class="field col-12 align-items-center flex font-light">
      Assigned to
    </div>
    <div
      v-for="u in taskUsers"
      class="formgrid p-fluid grid flex align-items-center"
      :key="u.uid"
    >
      <div :class="'col-6 field ' + (u.active !== 1 ? 'opacity-50' : '')">
        <div class="flex align-items-center">
          <ProgressAvatar
            :image="u.profile_picture"
            :user="u"
            :progress="u.progress_bar"
            :strokeColor="u.progress_bar >= 100 ? '#fe4d97' : '#6dd230'"
            innerStrokeColor="#ffffff"
            :radius="25"
            class="mr-2"
          ></ProgressAvatar>{{ u.name }} {{ u.surname }}
        </div>
      </div>
      <div :class="'col-6 field ' + (u.active !== 1 ? 'opacity-50' : '')">
        <div class="flex align-items-center justify-content-between">
          <div class="col text-right">
            <template v-if="u.estimated_hours">
              {{ !u.hours ? "00:00" : u.hours }} / {{ u.estimated_hours }}
              <div class="flex align-items-center mt-1">
                <div
                  class="surface-300 border-round overflow-hidden w-full"
                  style="height: 6px"
                >
                  <div
                    class="h-full"
                    :style="getProgressStyle(u)"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="col flex-grow-0">
            <AActiveSwitch
              @change="toggleUser(u.uid)"
              v-model="u.active"
            />
          </div>
        </div>
      </div>
    </div>

    <Button
      label="Assign a User"
      class="p-button-text p-button-secondary"
      @click="
        $store.dispatch('setSelectedObject', task);
        $store.dispatch('setPopupDialog', 'UserSelect');
      "
      icon="las la-plus"
    />
    <Divider class="m-2 mb-5 mt-3"></Divider>
    <div
      v-if="task?.review_user"
      class="field col-12 align-items-center flex font-light"
    >
      Reviewed by
    </div>
    <div class="formgrid p-fluid grid flex align-items-center">
      <div
        v-if="task?.review_user"
        :class="
          'col-6 field ' + (task?.review_user.active !== 1 ? 'opacity-50' : '')
        "
      >
        <div class="flex align-items-center">
          <ProgressAvatar
            :user="task?.review_user"
            :image="task?.review_user.profile_picture"
            :progress="task?.review_user.progress_bar"
            :strokeColor="
              task?.review_user.progress_bar >= 100 ? '#fe4d97' : '#6dd230'
            "
            innerStrokeColor="#ffffff"
            :radius="25"
            class="mr-2"
          ></ProgressAvatar>{{ task?.review_user.name }} {{ task?.review_user.surname }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { state, fetchTaskUsers } from "../../../services/data_service.js";

export default {
  props: {
    task: {
      type: Object,
    },
  },

  computed: {
    markdownDescription() {
      return this.task.description;
    },
    taskUsers() {
      return state.taskUsers;
    },
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    state.taskUsers = null;
    await this.loadData();
  },
  methods: {
    async loadData() {
      await fetchTaskUsers(this.task.uid);
    },
    getProgressStyle(task) {
      if (task.progress_bar >= 100) {
        return "background: #fe4d97;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;";
      } else {
        return (
          "border-bottom-left-radius: 4px;background: #6dd230;width:" +
          (task.progress_bar ? parseInt(task.progress_bar) : 0) +
          "%"
        );
      }
    },

    toggleUser(user_uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.task.uid +
            "/assign/" +
            user_uid,
          {}
        )

        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
</style>