<template>
  <div class="card col-12 pt-3">
    <!-- Filters Section -->
    <div class="flex flex-wrap justify-content-between align-items-center mb-3">
      <div class="flex">
        <!-- Dropdown Filters -->
        <Dropdown
          class="mr-3"
          :options="accountManagers"
          style="width: 200px;"
          optionLabel="label"
          optionValue="value"
          v-model="selectedAccountManager"
          placeholder="Account Manager"
        />

        <!-- <MultiSelect
          v-model="selectedIncludeOptions"
          style="width: 200px;"
          class="mr-3"
          :options="includeOptions"
          dataKey="value"
          optionLabel="label"
          placeholder="Include"
          display="chip"
        /> -->

      </div>

    </div>

    <DataTable
      :value="filteredJobs"
      rowGroupMode="subheader"
      groupRowsBy="client.name"
      responsiveLayout="scroll"
      sortMode="single"
      sortField="client.name"
      :rowClass="rowClass"
      :sortOrder="1"
      :filters.sync="filters"
      dataKey="uid"
      :rows="30"
      :rowsPerPageOptions="[30, 50, 100]"
      paginator
    >
      <template #empty>
        <div class="flex justify-content-center sm:h-20rem align-items-center sm:p-6 text-16">
          <div style="
          display: inline-block;
          padding: 30px 80px;
          background: #f8fafb;
          border-radius: 100px;
        ">No data to display
          </div>
        </div>
      </template>

      <!-- Group Header Template -->
      <template #groupheader="slotProps">
        <div class="font-bold pt-3 pb-2 sticky-header">
          {{ slotProps.data.client_code }} | {{ slotProps.data.client.name }}
        </div>
      </template>

      <!-- Table Columns -->
      <Column
        field="job_number"
        sortable
        header="No"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="75%"
          ></Skeleton>
          <template v-else>
            <div class="font-light-hover cursor-pointer">
              {{ slotProps.data?.job_number }}
            </div>
          </template>
        </template>
      </Column>

      <Column
        field="title"
        sortable
        header="Name"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="66%"
          ></Skeleton>
          <template v-else>
            <div
              class="cursor-pointer"
              style="max-width: 300px; overflow-x: clip; text-overflow: ellipsis; white-space: nowrap;"
            >
              {{ slotProps.data.title }}
            </div>
          </template>
        </template>
      </Column>

      <Column
        field="managed_by.name"
        sortable
        header="AE"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by name"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ slotProps.data.managed_by.name }}
          </template>
        </template>
      </Column>

      <Column
        field="status"
        sortable
        header="Status"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else-if="slotProps.data.status">
            <div
              class="text-12"
              style="width: 30px"
            >
              <span
                class="p-2"
                :style="
            slotProps.data.status === 'active' || slotProps.data.status === 'issued'|| slotProps.data.status === 'approved'
              ? 'background: #F4FBEF; color: #6dd230;  border-radius: 6px;'
              : 'background: #E8ECEF; border-radius: 6px;'
          "
              >
                {{ toTitleCase(slotProps.data.status) }}
              </span>
            </div>
          </template>
        </template>
      </Column>

      <Column
        field="date_created"
        header="Created"
        dataType="date"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else-if="slotProps.data.created_date?.date">
            {{ slotProps.data.created_date.date | moment("DD MMM YYYY") }}
          </template>
        </template>
      </Column>

      <Column
        field="metrics.time"
        header="Time"
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <div
            v-else-if="slotProps.data.metrics?.time?.tracked && slotProps.data.metrics.time.estimated"
            style="height:27px"
          >

            <div
              class="text-md"
              v-if="slotProps.data.metrics?.time?.tracked"
            >
              {{
            slotProps.data.metrics?.time?.tracked
              ? slotProps.data.metrics.time.tracked
              : "00:00"
          }}
              /
              {{
            slotProps.data.metrics?.time?.estimated
              ? slotProps.data.metrics.time.estimated
              : "00:00"
          }}
            </div>
            <div
              class="flex align-items-center mt-1"
              v-if="slotProps.data.metrics?.time?.tracked"
            >
              <div
                class="surface-300 border-round overflow-hidden w-full"
                style="height: 6px"
              >
                <div
                  class="h-full"
                  :style="getProgressStyle(slotProps.data)"
                />
              </div>
            </div>
          </div>
        </template>
      </Column>

      <Column
        field="total_incl"
        header="Total Incl."
        :styles="{ whiteSpace: 'nowrap' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ formatCurrency(slotProps.data.total_incl) }}
          </template>
        </template>
      </Column>
    </DataTable>

  </div>
</template>

<script>
import { fetchJobReports, state } from "../../../services/data_service";
export default {
  name: "ActiveJobs",
  data() {
    return {
      selectedAccountManager: null,
      selectedIncludeOptions: [],
      isLoading: true,
      groupByClient: false,
      filters: {
        global: { value: null, matchMode: "contains" },
      },
      jobs: [], // Jobs data
      accountManagers: [],
      includeOptions: [
        { label: "Cost Estimates - Draft", value: "cost_estimates_draft" },
        { label: "Cost Estimates - Issued", value: "cost_estimates_issued" },
        { label: "Invoices - Draft", value: "invoices_draft" },
        { label: "Invoices - Issued", value: "invoices_issued" },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.isLoading = false; // Simulate data being loaded
  },
  computed: {
    jobReports() {
      return state.jobReports;
    },
    filteredJobs() {
      return this.jobs.filter((job) => {
        if (this.selectedAccountManager) {
          return job.managed_by_filter === this.selectedAccountManager;
        }
        return true;
      });
    },
  },
  methods: {
    async loadData() {
      await fetchJobReports();
      var clients = [];

      for (var j in this.jobReports) {
        var job = this.jobReports[j];
        var existingClient = clients.find(
          (client) => client.uid === job.client.uid
        );
        if (!existingClient) {
          job.client.jobs = job.jobs;
          clients.push(job.client);
        } else {
          existingClient.jobs = existingClient.job.concat(job.jobs);
        }
      }

      clients.sort((a, b) => a.name.localeCompare(b.name));

      const jobs = [];
      clients.forEach((client) => {
        client.jobs.forEach((j) => {
          j.isJob = true;
          jobs.push(j);
          j.managed_by_filter = j.managed_by?.name;

          j.finances?.sort((a, b) => {
            if (a.transaction_type === "ce") {
              return -1;
            } else if (a.transaction_type === "inv") {
              return 1;
            }
            return 0;
          });

          j.finances?.forEach((f) => {
            f.isJob = false;
            f.client = client;
            f.managed_by_filter = j.managed_by?.name;
            f.job_number = f.document_number ?? "Draft";
            f.title = f.name ?? "N/A";
            f.created_date = { date: f.date_created ?? "N/A" };
            f.managed_by = { name: "" };
            f.status = f.status ?? "draft";
            jobs.push(f);
          });
        });
      });

      jobs.forEach((j) => {
        if (!j.client_code) {
          var c = jobs.find((x) => x.client_uid == j.client.uid);
          if (c) {
            j.client_code = c.client_code;
          }
        }
      });

      this.jobs = jobs;

      this.jobs.forEach((a) => {
        this.accountManagers.push({
          label: a.managed_by_filter,
          value: a.managed_by_filter,
        });
      });

      // disticnt the account managers
      this.accountManagers = this.accountManagers.filter(
        (v, i, a) => a.findIndex((t) => t.value === v.value) === i
      );
    },

    rowClass(data) {
      var style = "row-accessories row-accessories2 height-50 ";
      if (data.isJob) {
        style += "grey-background-custom";
      }
      return style;
    },
    navigateToJob(slotProps) {
      const route = `/job/${slotProps.data.uid}`;
      this.$router.push(route);
    },
    extractFirstName(name) {
      return name?.split(" ")[0] ?? "N/A";
    },
    getProgressStyle(task) {
      // Extract progress percentage from task's metrics, defaulting to 0 if missing

      var tracked = this.timeToSeconds(task.metrics.time.tracked);

      const progress =
        (tracked / (task.metrics.time.estimated_minutes * 60)) * 100;

      // Determine the progress bar's color and width based on the progress value
      if (progress >= 100) {
        return "background: #fe4d97; width: 100%;";
      } else if (progress >= 60) {
        return `background: #ffab2b; width: ${progress.toFixed(2)}%;`;
      } else {
        return `background: #6dd230; width: ${progress.toFixed(2)}%;`;
      }
    },
  },
};
</script>


<style >
.height-50 {
  height: 50px !important;
}
</style>